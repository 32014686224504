const Config = {
  app: {
    webaname: 'Illuminate',
    company: 'Illuminate',
    copyright: () => {
      let date = new Date()
      if (date.getFullYear() > 2021) {
        return '2021-' + date.getFullYear()
      } else {
        return '2021'
      }
    }
  },
  encrypt_key:"#@ILLUMINATE2021!!"
};
export default Config;