export default [
  /**
   * NO AUTH
   */
  {
    path: '/login',
    name: 'Login',
    component: () => import('@view/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      menu:'Login'
    },
  },

  /**
   * WITH AUTH
   */
  {
    path: "",
    component: () => import('@view/include/Layout.vue'),
    redirect: { name: "Dashboard" },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@view/Dashboard.vue'),
        meta: {
          menu: "Dashboard",
          pageTitle:'Dashboard',
        },
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('@view/Account.vue'),
        meta: {
          pageTitle: 'Account',
        },
      },
      {
        path: '/home',
        name: 'Home',
        component: () => import('@view/Home.vue'),
        meta: {
          menu: "Home",
          pageTitle: 'Home',
        },
      }, 
      {
        path: '/about',
        name: 'About',
        component: () => import('@view/About.vue'),
        meta: {
          menu: "About",
          pageTitle: 'About Us',
        },
      }, 
      {
        path: '/our-work/:pageSlug?',
        name: "OurWork",
        component: () => import ('@view/OurWork.vue'),
        meta: {
          menu: "OurWork",
          pageTitle: "Our Work"
        }
      },
      {
        path: '/services/:pageSlug?',
        name: "Services",
        component: () => import ('@view/Services.vue'),
        meta: {
          menu: "Services",
          pageTitle: "Services"
        }
      },
      {
        path: '/blog/blog-list/:pageSlug?',
        name: "Article",
        component: () => import('@view/Article.vue'),
        meta: {
          menu: "Article",
          pageTitle: "Blog List"
        }
      },
      {
        path: '/blog/blog-category/:pageSlug?',
        name: "ArticleCategory",
        component: () => import('@view/ArticleCategory.vue'),
        meta: {
          menu: "ArticleCategory",
          pageTitle: "Blog Category"
        }
      },
      {
        path: '/clients/:pageSlug?',
        name: "Clients",
        component: () => import('@view/Clients.vue'),
        meta: {
          menu: "Clients",
          pageTitle: "Client"
        }
      },
      {
        path: '/testimonial/:pageSlug?',
        name: "Testimonial",
        component: () => import('@view/Testimonial.vue'),
        meta: {
          menu: "Testimonial",
          pageTitle: "Testimonial"
        }
      },
      {
        path: '/career/:pageSlug?',
        name: 'Career',
        component: () => import('@view/Career.vue'),
        meta: {
          menu: "Career",
          pageTitle: 'Career',
        },
      },
      {
        path: '/legal/:pageSlug?',
        name: 'Legal',
        component: () => import('@view/Legal.vue'),
        meta: {
          menu: "Legal",
          pageTitle: 'Legal',
        },
      },
      {
        path: '/member/:pageSlug?',
        name: 'Member',
        component: () => import('@view/Member.vue'),
        meta: {
          menu: "Member",
          pageTitle: 'Member',
        },
      },
      {
        path: '/contact/contact-page/:pageSlug?',
        name: 'Contact',
        component: () => import('@view/Contact.vue'),
        meta: {
          menu: "Contact",
          pageTitle: 'Contact Setting',
        },
      },
      {
        path: '/contact/contact-message/:pageSlug?',
        name: 'ContactMessage',
        component: () => import('@view/ContactMessage.vue'),
        meta: {
          menu: "Contact",
          pageTitle: 'Contact Message',
        },
      },
      {
        path: '/user-management/user-list/:pageSlug?',
        name: 'Users',
        component: () => import('@view/Users.vue'),
        meta: {
          menu: "Users",
          pageTitle: 'User',
        },
      },
      {
        path: '/user-management/user-roles/:pageSlug?',
        name: 'UserLevel',
        component: () => import('@view/UserLevel.vue'),
        meta: {
          menu: "UserLevel",
          pageTitle: 'Access Level',
        },
      },
      {
        path: '/audittrail/:id?',
        name: 'AuditTrail',
        component: () => import('@view/AuditTrail.vue'),
        meta: {
          menu: "AuditTrail",
          pageTitle: 'Audit Trail',
        },
      },
    ],
  },
]
