import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement(
      "span",
      {
        class: 'mr-2 ml-2'
      },
      [
        createElement(
          'i',
          { class: 'fas fa-angle-down' },
        ),
      ],
    )
  }
})

Vue.component('VSelect', vSelect)

Vue.component('customSelect', {
  extends: vSelect,
  methods: {
    maybeAdjustScroll: () => false
  }
})