import Vue from 'vue'
import Vuex from 'vuex'
import Gen from '@/libs/Gen'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    production: process.env.VUE_APP_MODE == "production",
    baseUrl: process.env.VUE_APP_MODE ? location.origin : process.env.VUE_APP_BASE_URL,
    apiUrl: process.env.VUE_APP_API_URL,
    webTitle: process.env.VUE_APP_TITLE,
    meta:{ title:'Illuminate - Backoffice' },
    userToken: null,
    user: {},
    data: {}
  },
  getters: {
    // computed
    authenticated(state) {
      return state.userToken && state.user?.id
    },
    canManageAll(state, getters) {
      if (getters['authenticated']) {
        return (state.user?.moduleRole || []).indexOf('isAdministratorSP')>-1
      } else {
        return false
      }
    },
  },
  mutations: {
    // methods
    updateMeta(state, data = false) {
      state.meta = data || { title: 'Illuminate - Backoffice'}
    },
    updateData(state, data = {}) { 
      state.data = data 
    },
    updateUser(state, data = {}) {
      state.user = data
      //localStorage.setItem('bo_auth_data', JSON.stringify(state.user))
    },
    updateUserToken(state, data=null) {
      state.userToken = data
      Gen.setCookie("bo_auth", data, 30 * 3)
    },
    logoutUserToken: function (state) {
      state.userToken = null
      state.user = {}
      Gen.setCookie("bo_auth", "")
      localStorage.setItem('bo_auth_data', {})
    },
  },
  actions: {
    async watchAuth({ commit }) {
      commit('updateUserToken', Gen.getCookie('bo_auth'))
      try {
        let resp = await Gen.apiRest("/get/profile")
        resp = resp.data
        if (resp.status == "success") {
          commit('updateUserToken', resp.data.token )
          commit('updateUser', resp.data.user)
        } else {
          commit('updateUser',{})
        }
      } catch (error) {
        commit('updateUser',{})
      }
    },
    watchMeta() {
    }
  }
})
